import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'

import tokens from '../../../../api/tokens'
import users from '../../../../api/users'
import { ValidateEmailResponse, ValidationKeys, validateEmail } from '../../../../api/validations'
import Button from '../../../../components/Button'
import CenteredLoadingIndicator from '../../../../components/CenteredLoadingIndicator/CenteredLoadingIndicator.component'
import ReactFormContainer from '../../../../components/ReactFormWrapper/ReactFormWrapper.component'
import { openSnackbar } from '../../../../components/Snackbar/StoreInterface'
import TermsAndConditions from '../../../../components/TermsAndConditions/TermsAndConditions.component'
import { QUERY_KEY_TOKEN_USER_INFO } from '../../../../constants'
import EmailVerificationModal from '../../../../containers/EmailVerificationModal'
import useReturnButton from '../../../../hooks/useReturnButton'
import { setShowSnackbar } from '../../../../redux/slices/snackbarSlice'
import { setSpinner } from '../../../../redux/slices/spinnerSlice'
import { RootState } from '../../../../redux/store'
import { FormValues } from '../../../../shared/types'
import handleLogout from '../../../../utils/logout-utils'
import { validateServiceReturnUrl } from '../../../../utils/validation-utils'
import { StyledFormContainer, StyledTitle4 } from '../../Register.style'
import PasswordInputs from '../PasswordInputs/PasswordInputs.component'
import {
  EmailValidation,
  RegistrationFormSchema,
  RegistrationFormType,
  verificationKeyValidation,
} from './RegistrationForm.schema'
import { ButtonContainer, StyledParagraph16 } from './RegistrationForm.style'

interface IRegistrationForm {
  handleNext: () => void
}

const RegistrationForm: FunctionComponent<IRegistrationForm> = ({ handleNext }) => {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<RegistrationFormType>({
    defaultValues: {
      email: '',
      firstname: '',
      preferredFirstname: '',
      surname: '',
      preferredSurname: '',
      ssn: '',
      tel: '+358',
      password: '',
      confirm: '',
      terms: false,
    },
    resolver: zodResolver(RegistrationFormSchema),
    mode: 'onChange',
  })

  const { t } = useTranslation()
  const [externalValidationErrors, setExternalValidationErrors] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const returnButton = useReturnButton()

  const dispatch = useDispatch()

  const errorRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (errorRef.current && externalValidationErrors) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      errorRef.current.focus()
    }
  }, [externalValidationErrors])

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const hasAlreadyRegistered = useSelector((state: RootState) => state.me.uid)

  const userInfoQuery = useQuery([QUERY_KEY_TOKEN_USER_INFO], tokens.getUserInfo)
  const createUserMutation = useMutation({
    mutationFn: (values: FormValues) => users.createUser(values),
  })

  const validateEmailRequest = useMutation({
    mutationKey: [ValidationKeys.Email],
    mutationFn: validateEmail,
  })

  /**
   * By this useEffect we set the user's information to the form fields.
   */
  useEffect(() => {
    if (!userInfoQuery.data) {
      return
    }

    const { firstname, sn, hetu } = userInfoQuery.data.value
    setValue('firstname', firstname ? firstname[0] : '')
    setValue('preferredFirstname', firstname ? firstname[0] : '')
    setValue('surname', sn ? sn[0] : '')
    setValue('preferredSurname', sn ? sn[0] : '')
    setValue('ssn', hetu ? hetu[0] : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoQuery.data])

  /**
   * This useEffect handles the createUserMutation. If the request is loading we show the spinner.
   * After successful request we navigate the user to the next step.
   */
  useEffect(() => {
    if (createUserMutation.status === 'loading') {
      dispatch(setSpinner({ visible: true }))
    }

    if (createUserMutation.isError) {
      dispatch(setSpinner({ visible: false }))
      dispatch(openSnackbar(t('snackbar.user-creation-failed'), true))
    } else if (createUserMutation.status === 'success' && createUserMutation.data.errors) {
      dispatch(setSpinner({ visible: false }))

      return setExternalValidationErrors(createUserMutation.data.error)
    } else if (
      createUserMutation.status === 'success' &&
      !createUserMutation.data.errors &&
      !createUserMutation.data.error
    ) {
      tokens.getUserInfo()
      dispatch(setSpinner({ visible: false }))
      handleNext()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserMutation.data, createUserMutation.status])

  if (userInfoQuery.status === 'loading') return <CenteredLoadingIndicator />

  if (userInfoQuery.status === 'error') return <div>Error</div>

  const nameHelptext = t('account-creation.form-suomifi')

  /**
   * Handles form submit. Loading indicators are controlled in useEffect
   * @param values
   */
  const onSubmit = async (values: FormValues) => {
    try {
      createUserMutation.mutate(values)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * We validate e-mail on onBlur event. First we check if e-mail is valid and after that
   * we send a request to ubiserver to check if it passes also in there. If e-mail is already in
   * use it won't be passed.
   * @param email
   * @returns {Promise<void>}
   */
  const handleEmailValidation = async (email: string) => {
    setValue('verificationKey', '')

    if (email && email.trim().length === 0) {
      return
    }

    const validation = EmailValidation.safeParse(email)

    if (!validation.success) {
      return setError('email', { message: t('account-creation.form-invalid-email') as string })
    }

    clearErrors('email')
    clearErrors('verificationKey')
    dispatch(setSpinner({ visible: true }))
    validateEmailRequest.mutate(email, {
      onSuccess: () => {
        console.log('success')
        setIsModalOpen(true)
      },
      onError: data => {
        const response = data as AxiosError<ValidateEmailResponse>
        const message = response.response?.data?.details?.email
        dispatch(
          setShowSnackbar({
            error: true,
            showSnackbar: true,
            message: message || t('snackbar.email-already-in-use'),
          })
        )

        return setError('email', { message })
      },
      onSettled: () => {
        dispatch(setSpinner({ visible: false }))
      },
    })
  }

  /**
   * By this way we close the email verification modal and make user write a new e-mail
   */
  const handleEmailVerificationModalClose = () => {
    setIsModalOpen(false)
    setValue('email', '')
  }

  /**
   * We get the verification key from the modal and set it to the form.
   * @param vKey
   */
  const handleOnAfterEmailVerification = (vKey: string) => {
    dispatch(setSpinner({ visible: false }))
    setValue('verificationKey', vKey)
    clearErrors('verificationKey')
    setIsModalOpen(false)
  }

  /*
   * Handles the return button functionality.
   **/
  const handleReturnButton = async () => {
    if (returnButton) {
      try {
        const validatedReturnUrl = await validateServiceReturnUrl(
          returnButton.service,
          returnButton.href
        )
        await handleLogout(validatedReturnUrl)
      } catch (error) {
        console.error(error)
      }
    } else {
      await handleLogout('/')
    }
  }

  window.addEventListener(
    'message',
    event => {
      event.stopImmediatePropagation()

      if (
        event.origin === `${window.location.protocol}//${window.location.host}` &&
        event.data === 'success'
      ) {
        handleNext()
      }
    },
    false
  )

  const handlePasswordErrors = (hasErrors: boolean) => {
    if (hasErrors) {
      return setError('password', {
        type: 'custom',
        message: 'hasErrorsInPassword',
      })
    }

    clearErrors('password')
  }

  if (hasAlreadyRegistered) return <Navigate to="/portal" />

  console.log(errors, 'FormErrors for registration form')

  return (
    <>
      <StyledTitle4>{t('account-creation.form-title')}</StyledTitle4>
      <StyledParagraph16>
        {t('account-creation.form-body1')}
        <br />
        <br />
        {t('account-creation.form-body2')}
        {t('account-creation.form-body3')}
      </StyledParagraph16>
      <StyledFormContainer>
        <ReactFormContainer onSubmit={handleSubmit(onSubmit)}>
          <input type={'hidden'} {...register('firstname')} />
          <label htmlFor={'preferredFirstname'}>{t('account-creation.form-firstname')}*</label>
          <input type={'text'} {...register('preferredFirstname')} />
          <span className="help-text">{nameHelptext}</span>
          {errors.preferredFirstname ? (
            <span className="error-message">{t('account-creation.form-invalidfirstname')}</span>
          ) : null}
          <label htmlFor={'preferredSurname'}>{t('account-creation.form-lastname')}*</label>
          <input type={'text'} {...register('preferredSurname')} />
          <input type={'hidden'} {...register('surname')} />
          <span className="help-text">{nameHelptext}</span>
          {errors.preferredSurname ? (
            <span className="error-message">{t('account-creation.form-invalidsurname')}</span>
          ) : null}
          {watch('ssn') ? (
            <>
              <label htmlFor={'ssn'}>{t('account-creation.form-ssn')}</label>
              <input type={'text'} {...register('ssn')} readOnly={true} />
              <span className="help-text">{nameHelptext}</span>
            </>
          ) : null}
          <label htmlFor={'email'}>{t('account-creation.form-email')}*</label>
          <input
            type={'email'}
            {...register('email')}
            onBlur={e => handleEmailValidation(e.target.value)}
          />
          {errors.email ? <span className={'error-message'}>{errors.email.message}</span> : null}
          {verificationKeyValidation.safeParse(watch('verificationKey')).success ? (
            <span className="success-message bold">{t('account-creation.email-verified')}</span>
          ) : null}
          {!verificationKeyValidation.safeParse(watch('verificationKey')).success ? (
            <span className={'help-text'}>{t('account-creation.email-unverified')}</span>
          ) : null}
          {isModalOpen ? (
            <EmailVerificationModal
              email={watch('email')}
              isOpen={isModalOpen}
              onAfterSubmit={handleOnAfterEmailVerification}
              onCancel={() => handleEmailVerificationModalClose()}
            />
          ) : null}
          <input type={'hidden'} {...register('verificationKey')} />
          <label htmlFor={'tel'}>{t('account-creation.form-phone')}*</label>
          <input type={'tel'} {...register('tel')} />
          <span className="help-text">{t('account-creation.form-phone-helptext')}</span>
          {errors.tel ? (
            <span className="error-message">{t('account-creation.form-invalidtel')}</span>
          ) : null}
          <PasswordInputs
            passwordName={'password'}
            repeatPasswordName={'confirm'}
            errors={errors}
            t={t}
            register={register}
            email={watch('email')}
            preferredFirstname={watch('preferredFirstname')}
            preferredSurname={watch('preferredSurname')}
            watch={watch}
            onHasErros={(hasErrors: boolean) => handlePasswordErrors(hasErrors)}
          />
          <TermsAndConditions checkboxKey={'terms'} register={register} t={t} errors={errors} />
          <ButtonContainer>
            <Button
              label={t('account-creation.form-button-abort')}
              onClick={handleReturnButton}
              variant="secondary"
            />
            <Button type="submit" label={t('account-creation.form-button-next')} />
          </ButtonContainer>
        </ReactFormContainer>
      </StyledFormContainer>
    </>
  )
}

export default RegistrationForm
