import { FieldValidator } from 'final-form'

import { FormValues } from '../shared/types'

export const composeValidators =
  (...validators: FieldValidator<string>[]) =>
  (value: string) =>
    validators.reduce(
      (error: string, validator: FieldValidator<string>) => error || validator(value),
      ''
    )

export const formatRegistrationFormValues = (formValues: FormValues) => {
  return {
    firstname: formValues['firstname'],
    preferredFirstname: formValues['preferredFirstname'],
    surname: formValues['surname'],
    preferredSurname: formValues['preferredSurname'],
    email: formValues['email'],
    phone: formValues['tel'],
    password: formValues['password'],
    acceptedTerms: formValues['terms'],
    verificationKey: formValues['verificationKey'],
  }
}

export const formatChangePasswordFormValues = (formValues: FormValues) => {
  return {
    oldPassword: formValues['oldPassword'],
    newPassword: formValues['newPassword'],
  }
}

export const formatChangeUserBasicsFormValue = (formValues: FormValues) => {
  return {
    preferredFirstname: formValues['firstname'],
    preferredLastname: formValues['lastname'],
    phone: formValues['phone'],
  }
}

export const formatChangeEmailFormValues = (formValues: FormValues) => {
  return {
    email: formValues['email'],
    verificationKey: formValues['verificationKey'],
  }
}

/**
 * Checks if string has a value
 * @param string
 * @returns {boolean}
 */
export const stringHasValue = (string: string): boolean => {
  // Remove whitespaces and check length
  return string.trim().length > 0
}
