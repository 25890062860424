import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form'

import {
  BasicUserData,
  ValidatePasswordErrors,
  defaultPasswordValidationErrors,
  validatePasswordInput,
} from '../../../../routes/Portal/UserProfile/Password/ChangePassword.schema'
import PasswordValidationRow from './PasswordValidationRow.component'

//#region Types
type PasswordValidationRow = {
  key: keyof ValidatePasswordErrors
  i18nKey: string
}

type Props = {
  passwordName: string
  repeatPasswordName: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<Partial<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  t: TFunction
  email: string
  preferredFirstname: string
  preferredSurname: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>
  onHasErros: (hasErrors: boolean) => void
}
//#endregion

//#region Constants
const passwordValidationRows: PasswordValidationRow[] = [
  {
    key: 'lessThan16Chars',
    i18nKey: 'account-creation.form-invalidpassword-length',
  },
  {
    key: 'noNumberOrSpecialChar',
    i18nKey: 'account-creation.form-invalidpassword-number-specialchar',
  },
  {
    key: 'noUpperCaseOrLowerCase',
    i18nKey: 'account-creation.form-invalidpassword-uppercase-lowercase',
  },
  {
    key: 'includesTwoForbiddenChars',
    i18nKey: 'account-creation.form-invalidpassword-forbiddenchars',
  },
  {
    key: 'consecutiveChars',
    i18nKey: 'account-creation.form-invalidpassword-consecutive',
  },
]
//#endregion

const PasswordInputs = (props: Props) => {
  // This state is used to check if the password is valid
  const [errorMap, setErrorMap] = useState<ValidatePasswordErrors>(defaultPasswordValidationErrors)
  const [dirty, setIsDirty] = useState<boolean>(false)
  const {
    errors,
    register,
    t,
    email,
    preferredFirstname,
    preferredSurname,
    passwordName,
    repeatPasswordName,
    watch,
    onHasErros,
  } = props

  // Sets state to check if the password is valid
  const handlePasswordCheck = (password: string) => {
    if (!dirty && password.trim().length > 0) {
      setIsDirty(true)
    }

    const data: BasicUserData = {
      password,
      email,
      preferredFirstname,
      preferredSurname,
    }
    const validatedPassword = validatePasswordInput(data)
    // We check if the errorMap has any errors and pass this to the parent component
    const hasErrors = Object.values(validatedPassword).includes(true)
    onHasErros(hasErrors)
    setErrorMap(validatedPassword)
  }

  useEffect(() => {
    handlePasswordCheck(watch(passwordName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, preferredFirstname, preferredSurname])

  useEffect(() => {
    if (watch(passwordName) && watch(passwordName).trim().length > 0) {
      return
    }

    setErrorMap(defaultPasswordValidationErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(passwordName)])

  return (
    <>
      <label htmlFor={'password'}>{t('account-creation.form-password')}*</label>
      <input
        type={'password'}
        {...register(passwordName)}
        onChange={e => handlePasswordCheck(e.target.value)}
      />
      <span className="help-text">{t('account-creation.form-password-specification')}</span>
      {dirty
        ? passwordValidationRows.map(row => {
            return (
              <PasswordValidationRow
                i18nKey={row.i18nKey}
                error={errorMap[row.key]}
                t={t}
                key={row.key}
              />
            )
          })
        : null}
      <label style={{ marginTop: '1rem' }} htmlFor={'confirm'}>
        {t('account-creation.form-confirm-password')}*
      </label>
      <input type={'password'} {...register(repeatPasswordName)} />
      {errors[repeatPasswordName] ? (
        <span className="error-message">{t('account-creation.form-passwordsmustmatch')}</span>
      ) : null}
    </>
  )
}

export default PasswordInputs
