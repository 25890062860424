import { TFunction } from 'i18next'
import styled from 'styled-components'

import Icon from '../../../../components/Icon'
import { ColorEnum } from '../../../../shared/enums'

//#region Types
type Props = {
  t: TFunction
  i18nKey: string
  error: boolean
}
//#endregion

//#region Styled components
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  & > span {
    margin-left: 8px;
    font-size: 0.9rem;
  }
  .row-has-errors {
    color: ${ColorEnum.bfRed};
  }
`
//#endregion

const PasswordValidationRow: React.FC<Props> = (props: Props) => {
  const { t, i18nKey, error } = props

  return (
    <StyledRow>
      <Icon
        name={error ? 'close' : 'check'}
        color={error ? 'bf-red' : 'bf-green'}
        size={'smallest'}
      />
      <span className={error ? 'row-has-errors' : ''}>{t(i18nKey)}</span>
    </StyledRow>
  )
}

export default PasswordValidationRow
