import { z } from 'zod'

import {
  MIN_LENGTH_PASSWORD,
  PasswordSchema,
  RegistrationFormType,
  similarityExistsWithPassword,
} from '../../../../routes/Register/AccountCreation/RegistrationFormStep/RegistrationForm.schema'

//#region Constants
export const defaultPasswordValidationErrors = {
  lessThan16Chars: true,
  includesTwoForbiddenChars: true,
  consecutiveChars: true,
  noUpperCaseOrLowerCase: true,
  noNumberOrSpecialChar: true,
}
//#endregion

//#region CustomTypes
export type ValidatePasswordErrors = {
  lessThan16Chars: boolean
  includesTwoForbiddenChars: boolean
  consecutiveChars: boolean
  noUpperCaseOrLowerCase: boolean
  noNumberOrSpecialChar: boolean
}
export type BasicUserData = {
  email: string
  preferredFirstname: string
  preferredSurname: string
  password: string
}
//#endregion

//#region Functions
/**
 * This function returns an record of errors inside the password input. This function is used
 * in the ChangePasswordForm component to map potential errors in the password field.
 * @param password
 */
export function validatePasswordInput(data: BasicUserData): ValidatePasswordErrors {
  const { password } = data
  const errors = { ...defaultPasswordValidationErrors }

  // No upperCase or LowerCase
  if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
    errors.noUpperCaseOrLowerCase = false
  }

  // No number or special char
  if (/\d/.test(password) && /[!#$%&*@^]/.test(password)) {
    errors.noNumberOrSpecialChar = false
  }

  // This is client side validation of password length
  if (password.length >= MIN_LENGTH_PASSWORD) {
    errors.lessThan16Chars = false
  }

  // CHeck if contains two special characters in a row
  const regex = /([!#$%&*@^])\1+/g
  const matches = password.match(regex)

  if (password.length >= MIN_LENGTH_PASSWORD && !matches?.length) {
    errors.includesTwoForbiddenChars = false
  }

  // If password contains three consecutive characters from users e-mail, firstname or lastname, it is invalid. This is from ubisecure.
  const hasSimilarity = similarityExistsWithPassword(data as RegistrationFormType)

  if (!hasSimilarity && password.length >= MIN_LENGTH_PASSWORD) {
    errors.consecutiveChars = false
  }

  return errors
}
//#endregion

// #region Validation
export const ChangePasswordValidation = z
  .object({
    newPassword: PasswordSchema,
    confirm: PasswordSchema,
    email: z.string(),
    preferredFirstname: z.string(),
    preferedSurname: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword && data.confirm.length > 0 && data.newPassword !== data.confirm) {
      ctx.addIssue({
        path: ['confirm'],
        message: 'account-creation.form-passwordsmustmatch',
        code: z.ZodIssueCode.custom,
      })
    }

    const mutatedData = {
      password: data.newPassword,
      confirm: data.confirm,
      email: data.email,
      preferredFirstname: data.preferredFirstname,
      preferredSurname: data.preferedSurname,
    } as RegistrationFormType
    const similarityExists = similarityExistsWithPassword(mutatedData)

    if (similarityExists) {
      ctx.addIssue({
        path: ['newPassword'],
        message: 'microcopies.form-password-consecutive',
        code: z.ZodIssueCode.custom,
      })
    }
  })
// #endregion

//#region Types
export type ChangePasswordFormValues = z.infer<typeof ChangePasswordValidation>
//#endregion
